<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="商品名称" prop="title">
        <el-input v-model="info.title" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="供货商">
<!--        <el-input v-model="info.supplier_name" placeholder="请输入供货商名称"></el-input>-->
        <el-select v-model="info.supplier_id" placeholder="请选择">
          <el-option
              v-for="item in supplierList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
<!--      <el-form-item label="所属车型">-->
<!--        <el-select v-model="info.car_mod_ids" multiple placeholder="请选择" style="width: 100%">-->
<!--          <el-option-->
<!--              v-for="item in carModelList"-->
<!--              :key="item.id"-->
<!--              :label="item.title"-->
<!--              :value="item.id">-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
      <el-form-item label="商品分类" prop="menu_ids">
        <div v-if="menuList">
          <el-tabs type="border-card" v-for="(item,index) in menuList" :key="index" class="mg-bt-10">
            <el-tab-pane :label="item.title">
              <div v-for="(child,cindex) in item.children" :key="cindex">
                <el-row :gutter="20" v-if="child.children && child.children.length>0">
                  <el-col :span="2">{{ child.title }}：</el-col>
                  <el-col :span="18">
                    <el-checkbox-group v-model="info.menu_ids" :name="child.id">
                      <el-checkbox :label="three.id" :value="three.id" v-for="(three,tindex) in child.children" :key="tindex">{{ three.title }}</el-checkbox>
                    </el-checkbox-group>
                  </el-col>
                </el-row>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-form-item>
      <el-form-item label="商品图" prop="imgs">
        <SelectImgs :selectNum="5" :selectData="imgs" :selectFunc="imgChoose" :deleteFunc="deleteFunc" :idx="1"></SelectImgs>
        <span style="color: #ccc;">最多5张图，第一张图为默认图</span>
      </el-form-item>
      <el-form-item label="视频" prop="video">
        <div>
          <el-upload
              ref="upload"
              :action="uploadUrl"
              name="file"
              :data="picdata"
              :show-file-list="false"
              :multiple="true"
              :headers="headers"
              :before-upload="beforeUpload"
              :on-success="uploadSuccess"
              :on-error="uploadError">
            <el-button type="primary" icon="el-icon-upload2">上传视频</el-button>
          </el-upload>
          <div v-if="info.video && info.video.length > 0">
            <div v-for="(item,index) in info.video" :key="index">
              <span class="mg-lf-10" style="color: blue">{{ item.title }}</span>
              <a class="mg-lf-10" :href="item.url" target="_blank">预览</a>
              <el-popconfirm
                  title="确认删除视频吗？"
                  class="mg-lf-10"
                  @confirm="delVideo(index)"
                  v-if="info.video"
              >
                <el-button type="text" slot="reference">删除</el-button>
              </el-popconfirm>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="简介">
        <el-input type="textarea" rows="4" v-model="info.intro" placeholder="请输入简介"></el-input>
      </el-form-item>
      <el-form-item label="是否包邮">
        <el-radio-group v-model="info.freight_free">
          <el-radio :label="0">不包邮</el-radio>
          <el-radio :label="1">包邮</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="规格">
        <el-radio-group v-model="info.is_sku" :disabled="info.id > 0">
          <el-radio :label="0">单规格</el-radio>
          <el-radio :label="1">多规格</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="价格" v-if="info.is_sku==0">
        <el-input v-model="info.price" placeholder="请输入价格">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="外部合作方价格" v-if="info.is_sku==0">
        <el-input v-model="info.partner_price" placeholder="请输入价格">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="建议零售价" v-if="info.is_sku==0">
        <el-input v-model="info.old_price" placeholder="请输入价格">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="成本价" v-if="info.is_sku==0">
        <el-input v-model="info.cost_price" placeholder="请输入价格">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="库存" v-if="info.is_sku==0">
        <el-input v-model="info.stock" placeholder="请输入库存"></el-input>
      </el-form-item>
      <el-form-item label="" v-if="info.is_sku==1">
        <div class="mg-bt-5">
          <el-select v-model="specsIdx" placeholder="请选择规格模板" class="mg-rt-5">
            <el-option label="请选择规格模板" value="0"></el-option>
            <el-option
                v-for="child in specsTempList"
                :key="child.id"
                :label="child.title"
                :value="child.id">
            </el-option>
          </el-select>
          <el-button type="primary" @click="useTemp">使用该模板</el-button>
        </div>
        <div v-if="templateInfo && templateInfo.length > 0">
          <div v-for="(item,index) in templateInfo" :key="index">
            <div class="mg-bt-5">
              <el-tag closable effect="dark" size="medium" @close="deleteMain(index)">{{ item.title }}</el-tag>
            </div>
            <el-tag class="mg-rt-10 mg-bt-5 fl" closable v-for="(child,index2) in item.txt" :key="index2" @close="deleteChild(index,index2)">{{ child }}</el-tag>
            <span style="width: 200px; float: left;">
              <el-input placeholder="请输入属性名称" class="font12" v-model="item.word">
                <el-button type="primary" slot="append" class="iv-search" @click="saveChild(index)">添 加</el-button>
              </el-input>
              </span>
            <div class="clr mg-bt-10"></div>
          </div>
        </div>
        <el-button type="primary" size="small" icon="el-icon-plus" @click="showMenu()" v-if="!showInput && templateInfo.length < 3">添加新规格</el-button>
      </el-form-item>
      <el-form-item label="新增" v-if="showInput && info.is_sku==1">
        <el-row>
          <el-col :span="5" class="mg-rt-10"><el-input v-model="specsInfo.title" autocomplete="off" placeholder="请输入规格名"><template slot="prepend">规格名：</template></el-input></el-col>
          <el-col :span="5"><el-input v-model="specsInfo.value" autocomplete="off" placeholder="请输入规格值"><template slot="prepend">规格值：</template></el-input></el-col>
          <el-col :span="3" style="text-align: right">
            <el-button type="primary" @click="saveMain" size="small">确 定</el-button>
            <el-button @click="showInput = false" size="small">取 消</el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="" v-if="info.is_sku==1">
        <el-button type="primary" @click="createList">立即生成</el-button>
        <el-table
            :data="tableData"
            :key="tableRandKey"
            ref="rfTable"
            border
            style="width: 92%; margin-top: 10px;"
            size="mini">
          <el-table-column v-for="(item,index) in tableHeader" :key="index"
              :prop="item.field"
              :label="item.title"
              align="center"
              width="140">
          </el-table-column>
          <el-table-column
              prop="img"
              label="图片"
              align="center"
              min-width="120">
            <template slot-scope="scope">
              <SelectImgs :selectNum="1" :selectData="[scope.row.img]" :selectFunc="imgSpecChoose" :idx="scope.$index"></SelectImgs>
              <el-input v-model="scope.row.rk" autocomplete="off" placeholder="请输入价格" style="display: none"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              prop="price"
              label="价格"
              align="center"
              width="140">
            <template slot-scope="scope">
              <el-input v-model="scope.row.price" autocomplete="off" placeholder="请输入价格"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              prop="partner_price"
              label="外部合作方价格"
              align="center"
              width="140">
            <template slot-scope="scope">
              <el-input v-model="scope.row.partner_price" autocomplete="off" placeholder="请输入价格"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              prop="old_price"
              label="建议零售价"
              align="center"
              width="140">
            <template slot-scope="scope">
              <el-input v-model="scope.row.old_price" autocomplete="off" placeholder="请输入价格"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              prop="cost_price"
              label="成本价"
              align="center"
              width="140">
            <template slot-scope="scope">
              <el-input v-model="scope.row.cost_price" autocomplete="off" placeholder="请输入价格"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              prop="stock"
              label="库存"
              align="center"
              width="140">
            <template slot-scope="scope">
              <el-input v-model="scope.row.stock" autocomplete="off" placeholder="请输入库存"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="100">
            <template slot-scope="scope">
              <el-popconfirm
                  title="确认删除该规格吗？"
                  @confirm="deleteSpecs(scope.$index)"
              >
                <el-button slot="reference" type="text" size="small">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="客服二维码" prop="kefu_qrcode">
        <SelectImgs :selectNum="1" :selectData="kefu_qrcode" :selectFunc="imgChoose" :idx="2"></SelectImgs>
      </el-form-item>
      <el-form-item label="独立收款码" prop="paycode_status">
        <el-switch
            v-model="info.paycode_status"
            active-value="1"
            inactive-value="0">
        </el-switch>
      </el-form-item>
      <el-form-item label="微信收款码" prop="wx_payment_code" v-if="info.paycode_status==1">
        <SelectImgs :selectNum="1" :selectData="[this.info.wx_payment_code]" :selectFunc="picChoose" :idx="1"></SelectImgs>
      </el-form-item>
      <el-form-item label="支付宝收款码" prop="ali_payment_code" v-if="info.paycode_status==1">
        <SelectImgs :selectNum="1" :selectData="[this.info.ali_payment_code]" :selectFunc="picChoose" :idx="2"></SelectImgs>
      </el-form-item>
      <el-form-item label="第三方收款名称" v-if="info.paycode_status==1">
        <el-input v-model="info.payment_name" placeholder="请输入名称" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="第三方收款码" prop="payment_code" v-if="info.paycode_status==1">
        <SelectImgs :selectNum="1" :selectData="[this.info.payment_code]" :selectFunc="picChoose" :idx="3"></SelectImgs>
      </el-form-item>
      <el-form-item label="转账信息" v-if="info.paycode_status==1">
        <el-input type="textarea" rows="4" v-model="info.bank_code" placeholder="请输入收款账号信息"></el-input>
      </el-form-item>
      <el-form-item label="注意事项" prop="note">
         <wang-edit :txt="info.note" :callback="setText" :idx="1"></wang-edit>
      </el-form-item>
      <el-form-item label="产品详情" prop="content">
        <wang-edit :txt="info.content" :callback="setText" :idx="2"></wang-edit>
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model="info.ordering" placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="closePage()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import WangEdit from "../../components/WangEdit";
import SelectImgs from '../../components/SelectImgs'
import config from "@/config/config";
import {getToken} from "@/utils/auth";
export default {
  data() {
    return {
      uploadUrl: config.HTTP_REQUEST_URL + 'admin/1.0/common/uploadimg',
      headers: {
        authorization: 'Bearer ' + getToken()
      },
      picdata: {
        type: 'file',
        file_type: 2
      },
      tableHeader: [],
      tableData: [],
      menuList: [],
      carModelList: [],
      supplierList: [],
      specsTempList: [],
      specsIdx: '',
      templateInfo: [],
      imgs: [],
      kefu_qrcode: [],
      tableRandKey: 1,
      info: {
        id: 0,
        supplier_id: '',
        menu_ids: [],
        car_mod_ids: [],
        imgs: '',
        video: '',
        intro: '',
        price: '',
        partner_price: '',
        cost_price: '',
        kefu_qrcode: '',
        stock: '',
        note: '',
        content: '',
        is_sku: 0,
        freight_free: 0,
        supplier_name: ''
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          {required: true, message: '请输入商品名称', trigger: 'blur'}
        ],
        menu_ids: [
          {required: true, message: '请选择分类', trigger: 'blur'}
        ]
      },
      showInput: false,
      specsInfo: {
        title: '',
        value: ''
      }
    };
  },
  created() {
    this.getMenuList()
    this.getSupplierList()
    this.getCarModelList()
    this.getSpecsList()
    if (this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
  },
  components: {
    SelectImgs,
    WangEdit
  },
  computed: {},
  methods: {
    picChoose(data,idx) {
      if(idx==1) {
        this.info.wx_payment_code = data.pic
      } else if(idx==2) {
        this.info.ali_payment_code = data.pic
      } else if(idx==3) {
        this.info.payment_code = data.pic
      }
    },
    beforeUpload(file) {
      const isJPG = file.type === 'video/mp4'
      const isLt2M = file.size / 1024 / 1024 < 25;
      if (!isJPG) {
        this.$message.error('请上传mp4格式的视频!');
      }
      if (!isLt2M) {
        this.$message.error('上传视频大小不能超过 25MB!');
      }
      return isJPG && isLt2M;
    },
    uploadSuccess(res, file) {
      if(res.errcode == 0) {
        this.success('上传成功')
        this.info.video.push({
          title: res.data.title,
          url: res.data.url
        })
      } else {
        this.fail(res.errmsg)
      }
    },
    uploadError(res, file) {
      this.fail('上传失败，请重新上传!')
    },
    delVideo(idx) {
      this.info.video.splice(idx,1)
    },
    showMenu() {
      this.showInput = true
    },
    saveMain() {
      if(this.specsInfo.title == '') {
        this.fail('请输入规格名')
      } else if(this.specsInfo.value == '') {
        this.fail('请输入属性值')
      } else {
        this.templateInfo.push({title: this.specsInfo.title.trim(), txt: [this.specsInfo.value.trim()]})
        this.clrSpecs()
        this.showInput = false
      }
    },
    saveChild(index) {
      if(!this.templateInfo[index].word) {
        this.fail('请输入属性名称')
      } else if(this.templateInfo[index].txt.indexOf(this.templateInfo[index].word) > -1) {
        this.fail('该属性已存在')
      } else {
        this.templateInfo[index].txt.push(this.templateInfo[index].word.trim())
        this.templateInfo[index].word = ''
      }
    },
    deleteMain(index) {
      this.templateInfo.splice(index,1)
    },
    deleteChild(index,index2) {
      this.templateInfo[index].txt.splice(index2,1)
    },
    clrSpecs() {
      this.specsInfo = {title: '', value: ''}
    },
    setText(txt,idx) {
      if(idx==1) {
        this.info.note = txt
      } else {
        this.info.content = txt
      }
    },
    imgChoose(item,idx) {
      var _this = this
      if(idx==1) {
        var pics = this.imgs
        item.forEach(function (child) {
          if(_this.imgs.length < 5) {
            pics.push(child.pic)
          }
        })
        this.imgs = pics
        this.info.imgs = pics
      } else {
        this.kefu_qrcode = [item.pic]
        this.info.kefu_qrcode = item.pic
      }
    },
    imgSpecChoose(item,idx) {
      this.tableData[idx].img = item.pic
      this.tableData[idx].rk = Math.random()
    },
    deleteSpecs(idx) {
      this.tableData.splice(idx,1)
    },
    deleteFunc(idx) {
      var imgs = JSON.parse(JSON.stringify(this.imgs))
      imgs.splice(idx,1)
      this.imgs = imgs
      this.info.imgs = imgs
    },
    getMenuList() {
      this.$api.goods.menuList({}, res => {
        if (res.errcode == 0) {
          this.menuList = res.data
        }
      })
    },
    getSupplierList() {
      this.$api.merchant.supplierIndex({page_size: 100}, res => {
        if (res.errcode == 0) {
          this.supplierList = res.data.data
        }
      })
    },
    getCarModelList() {
      this.$api.merchant.cartypeIndex({page_size:100},res=>{
        if(res.errcode==0) {
          this.carModelList = res.data.data
        }
      })
    },
    getSpecsList() {
      this.$api.goods.specsTemplateIndex({page_size: 100}, res => {
        if (res.errcode == 0) {
          this.specsTempList = res.data.data
        }
      })
    },
    useTemp() {
      var _this = this
      _this.showInput = false
      this.specsTempList.forEach(function (item) {
        if(item.id == _this.specsIdx) {
          _this.templateInfo = JSON.parse(JSON.stringify(item.content))
        }
      })
    },
    createList() {
      var _this = this
      var oldData = JSON.parse(JSON.stringify(_this.tableData))
      var tableHeader = []
      var tableBody = []
      var tableData = []
      if(_this.templateInfo) {
        _this.templateInfo.forEach(function (item,index) {
          tableHeader.push({title:item.title,field:'value'+index})
          tableBody.push(item.txt)
        })
      }
      this.fs(tableBody).forEach(function (item,index) {
        var info = {price:'',old_price:'',cost_price:'',img:'',stock:'',rk:'',txt:[]}
        if(item instanceof Array) {
          item.forEach(function (child,cindex) {
            eval("info.value"+cindex+"='"+child+"'")
            info.txt.push(child.trim())
          })
        } else {
          info.txt = [item]
          info.value0 = item
        }
        tableData.push(info)
      })
      _this.tableHeader = tableHeader
      tableData.forEach(function (item) {
        var info = _this.compareArr(item.txt,oldData)
        if(info) {
          item.img = info.img
          item.price = info.price
          item.old_price = info.old_price
          item.cost_price = info.cost_price
          item.stock = info.stock
        }
      })
      _this.tableData = tableData
    },
    compareArr(arr1,arr2) {
      for(var i=0; i<arr2.length; i++) {
        if(arr1.join(',') == arr2[i].txt.join(',')) {
          console.log(arr2[i])
          return arr2[i]
        }
      }
      return null
    },
    fs(array){
      if (array.length < 2) return array[0] || [];
      return array.reduce((total, currentValue) => {
        let res = [];
        total.forEach(t => {
          currentValue.forEach(cv => {
            if (t instanceof Array) 	// 或者使用 Array.isArray(t)
              res.push([...t, cv]);
            else
              res.push([t, cv]);
          })
        })
        return res;
      })
    },
    getInfo() {
      var that = this
      this.$api.goods.goodsInfo({id: that.info.id}, function (res) {
        if (res.errcode == 0) {
          that.info = res.data
          if(that.info.imgs) {
            that.imgs = that.info.imgs
          }
          if(that.info.kefu_qrcode) {
            that.kefu_qrcode = [that.info.kefu_qrcode]
          }
          if(that.info.supplier_id == 0) {
            that.info.supplier_id = '';
          }
          that.info.paycode_status = String(that.info.paycode_status)
          if(that.info.attrList) {
            that.templateInfo = that.info.attrList
            that.tableHeader = []
            that.templateInfo.forEach(function (item,index) {
              that.tableHeader.push({title:item.title,field:'value'+index})
            })
          }
          if(that.info.specsList) {
            that.tableData = that.info.specsList
          }
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(that.info.is_sku==1 && that.tableData.length < 1) {
            that.fail('请创建商品规格')
            return false
          }
          var param = this.info
          if(param.is_sku==1) {
            param.specsList = that.tableData
            param.attrList = that.templateInfo
          }
          if (this.info.id == 0) {
            this.$api.goods.goodsAdd(param, function (res) {
              if (res.errcode == 0) {
                that.success(res.errmsg)
                that.closePage()
              } else {
                that.fail(res.errmsg)
              }
            })
          } else {
            this.$api.goods.goodsEdit(param, function (res) {
              if (res.errcode == 0) {
                that.success(res.errmsg)
                that.closePage()
              } else {
                that.fail(res.errmsg)
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    closePage() {
      setTimeout(function () {
        window.close()
      },500)
    }
  }
};
</script>
<style scoped>
.box {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  min-height: 500px;
}
</style>
